import React from "react"
import { graphql, PageProps } from "gatsby"
import Layout from "../../components/layout"
import Header from "../../components/header"
import SectionLoader from "../../sections/section-loader"
import Footer from "../../components/footer"
import { homepageSettings } from "./homepage-settings"
import { MainContent } from "../../ui"
import {
  HomepageQuery, IAllNews,
  IBanner,
  IFooter,
  IMediaAsset,
  INavigationBar,
  Scalars
} from "../../../gatsby-graphql"
import { ILocaleLink } from "../../components/top-bar/common/Locales"
import { LayoutStyles } from "./homepage-styles"

interface PageContext {
  locale: string
  locales: ILocaleLink[]
}

interface HomepageProps extends PageProps {
  data: HomepageQuery
  pageContext: PageContext
}

const Homepage = ({ data, pageContext }: HomepageProps) => {
  return (
    <Layout topBar={data.topBar} seoTitle={""} seo={data.homepage?.seo || undefined} locales={pageContext.locales} additionalStyles={LayoutStyles} locale={pageContext.locale}>
      <Header
        data={data.homepage?.header as IBanner}
        navBarData={data.navBar as INavigationBar}
        className={homepageSettings.headerClass}
        locales={pageContext.locales}
        rwdLogo={data.global?.logo as IMediaAsset}
        locale={pageContext.locale}
      />
      <MainContent>
        {data.homepage?.sections.length > 0 &&
          data.homepage?.sections.map((section: Scalars["JSON"], index: number) => (
            <SectionLoader data={section} locale={pageContext.locale} key={index}/>
          ))}
      </MainContent>
      <Footer data={data.footer as IFooter}
              locales={pageContext.locales}
              locale={pageContext.locale}
      />
    </Layout>
  )
}

export default Homepage

export const pageQuery = graphql`
  query Homepage($id: String!, $locale: String!) {
    global: strapiGlobal {
      ...FGlobal
    }
    navBar: strapiNavigationBar(locale: { eq: $locale }) {
      ...FNavigationBar
    }
    topBar: strapiTopBar(locale: { eq: $locale }) {
      ...FTopBar
    }
    footer: strapiFooter(locale: { eq: $locale }) {
      ...FFooter
    }
    homepage: strapiHomepage(id: { eq: $id }) {
      ...FHomepage
    }
  }
  fragment FHomepage on StrapiHomepage {
    id
    title
    seo {
      ...FSeo
    }
    header {
      ...FBanner
    }
    sections
  }
`
