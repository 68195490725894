import { css } from "styled-components"
import { BreakPoints, media } from "../../helpers/rwd"
import { HeaderWrapper } from "@gamigo/gatsby-theme-strapi/src/components/header/header-elements"

export const LayoutStyles = css`
  ${HeaderWrapper} {
    ${media.max(BreakPoints.tablet)} {
      h1 {
        display: none;
      }
    }
  }
`
